.menu{
    top: 5px;
    left: calc(100% - .5em*2 - 9.675em);
    margin-bottom: 1px;
    margin-left : 1px;
    width: 145px;
    height: 195px;
    color: white;
    position: absolute;
    bottom: 0;
    border-radius: 0px 0px 0px 30px;
    font-family: Tahoma;
    font-size: normal;
} 
.stack-top-info{
  z-index: 9;
  margin: 10px; 
}
.menu-header{
    background: #1868B7;
    width: 60px;
    height: 20px;
    margin-left: auto; 
    margin-right: 0;
    left: 0;
    display: flex;
    align-items:right;
    justify-content: center;
    padding: 5px 5px;
    border-radius: 10px 0px 0px 0px;
    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.0) -2px -2px 8px 0px;
}
.menu-content{
    border-radius: 10px 0px 0px 0px;
    padding-top: 5px;
    padding-bottom: 5px; 
    background:rgba(83, 27, 147,0.7);
    box-shadow: rgba(0, 0, 0, 0.50) 0px 3px 8px;
}
.infobar-content{
    padding: 7px 15px;
    color:white;
    text-align: right;
    width : 115px;
}
.infobar-content:hover{
    font-weight: bold;
    cursor: pointer;
}
.infobar-footer{
    padding: 7px 15px;
    color:white;
    text-align: right;
    width : 115px;
    font-size: x-small;
    background:rgba(83, 27, 147,0.4);
    border-radius: 0px 0px 0px 10px;
    box-shadow: rgba(0, 0, 0, 0.0) -2px -2px 8px 0px;
}

.popup-container{
    display: flex;
    width: 99vw;
    height: 99vh;
    justify-content: center;
    align-items: center;
    position: absolute;
    display:none;
}
#popup_faq a:link { color: #FFFFFF; }
#popup_faq a:visited { color: #FFFFFF; }
#popup_faq a:hover { color: #2BCDE4; }
#popup_faq a:active { color: #2BCDE4; }
.popup{
    width: 500px;
    height: 325px;
    background:rgba(83, 27, 147,0.7);
    color: white;
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.50) 0px 3px 8px;
    border-radius: 0px 0px 30px 30px;
    font-family: Tahoma;
} 
.popup-social{
    width: 500px;
    height: 525px;
    background:rgba(83, 27, 147,0.7);
    color: white;
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.50) 0px 3px 8px;
    border-radius: 0px 0px 30px 30px;
    font-family: Tahoma;
} 
.stack-top-popup{
  z-index: 5;
  margin: 10px; 
}
.popup-header{
    background: #1868B7;
    width: 100%px;
    height: 10px;
    display: flex;
    align-items:center;
    justify-content: left;
    padding: 20px;
    font-weight: 600;
}
.popup-content{
    margin-top: 5px;
    padding: 15px 20px 5px;
    height: 235px;
    width: 450px;
    color:white;
    font-size: medium;
    background:rgba(83, 27, 147,0.0);
    overflow-y:auto;
}
.popup-content-contact{
    margin-top: 5px;
    padding: 15px 20px 5px;
    height: 425px;
    width: 450px;
    color:white;
    font-size: medium;
    background:rgba(83, 27, 147,0.0);
    overflow-y:auto;
}
.popup-content-social{
    padding: 20px 20px 20px;
    height: 435px;
    width: 480px;
    color:white;
    background:rgba(83, 27, 147,0.0);
}
#popup_about a:link { color: #FFFFFF; }
#popup_about a:visited { color: #FFFFFF; }
#popup_about a:hover { color: #2BCDE4; }
#popup_about a:active { color: #2BCDE4; }
#popup_contact a:link { color: #FFFFFF; }
#popup_contact a:visited { color: #FFFFFF; }
#popup_contact a:hover { color: #2BCDE4; }
#popup_contact a:active { color: #2BCDE4; }




